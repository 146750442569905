import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import RedLiner from './RedLiner';
import { buttonStyle, inputStyle } from '../../utils/exampleStyles.ts';
import { CustomInfoBox } from '../../utils/exampleComponents.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "redliner"
    }}>{`RedLiner`}</h1>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={RedLiner} mdxType="Props" />
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <h3 {...{
      "id": "basic-example"
    }}>{`Basic Example`}</h3>
    <p>{`Wrapping a `}<inlineCode parentName="p">{`RedLiner`}</inlineCode>{` component around a button displays the height, width, and an `}<inlineCode parentName="p">{`InfoBox`}</inlineCode>{` which contains additional attributes.`}</p>
    <Playground __position={1} __code={'<RedLiner>\n  <button style={buttonStyle}>Measure Me</button>\n</RedLiner>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      RedLiner,
      buttonStyle,
      inputStyle,
      CustomInfoBox
    }} mdxType="Playground">
    <RedLiner mdxType="RedLiner">
      <button style={buttonStyle}>
        Measure Me
      </button>
    </RedLiner>
    </Playground>
    <h3 {...{
      "id": "show-on-hover"
    }}>{`Show on Hover`}</h3>
    <p>{`Use the prop `}<inlineCode parentName="p">{`showOnHover`}</inlineCode>{` to only show RedLiner when the component is hovered.`}</p>
    <Playground __position={2} __code={'<RedLiner showOnHover>\n  <button style={buttonStyle}>Hover Me</button>\n</RedLiner>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      RedLiner,
      buttonStyle,
      inputStyle,
      CustomInfoBox
    }} mdxType="Playground">
  <RedLiner showOnHover mdxType="RedLiner">
    <button style={buttonStyle}>Hover Me</button>
  </RedLiner>
    </Playground>
    <h3 {...{
      "id": "configuring-options"
    }}>{`Configuring Options`}</h3>
    <p>{`Besides hover control, most customization is done through the `}<inlineCode parentName="p">{`config`}</inlineCode>{` prop, which is an object containing the following properties:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`color`}</inlineCode>{` (string): the color of the lines for height and width`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`displayOpts`}</inlineCode>{` ('all' | Array<'height' | 'info' | 'width'>): which parts of RedLiner are shown`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`infoOpts`}</inlineCode>{` (string[]): which CSS properties are shown inside the InfoBox`}</li>
    </ul>
    <Playground __position={3} __code={'<RedLiner\n  config={{\n    color: \'purple\',\n    displayOpts: [\'height\', \'info\'],\n    infoOpts: [\'borderRadius\', \'color\'],\n  }}\n  showOnHover\n>\n  <button style={buttonStyle}>Measure Me</button>\n</RedLiner>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      RedLiner,
      buttonStyle,
      inputStyle,
      CustomInfoBox
    }} mdxType="Playground">
  <RedLiner config={{
        color: 'purple',
        displayOpts: ['height', 'info'],
        infoOpts: ['borderRadius', 'color']
      }} showOnHover mdxType="RedLiner">
    <button style={buttonStyle}>Measure Me</button>
  </RedLiner>
    </Playground>
    <h2 {...{
      "id": "custom-components"
    }}>{`Custom Components`}</h2>
    <p>{`It's possible to replace the `}<inlineCode parentName="p">{`InfoBox`}</inlineCode>{` and `}<inlineCode parentName="p">{`Line`}</inlineCode>{` components by passing your custom component to the `}<inlineCode parentName="p">{`components`}</inlineCode>{` prop. In this example, we replace the default InfoBox component with a custom `}<inlineCode parentName="p">{`InfoBox`}</inlineCode>{` component.`}</p>
    <Playground __position={4} __code={'<RedLiner components={{ InfoBox: CustomInfoBox }}>\n  <button style={buttonStyle}>Measure Me</button>\n</RedLiner>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      RedLiner,
      buttonStyle,
      inputStyle,
      CustomInfoBox
    }} mdxType="Playground">
  <RedLiner components={{
        InfoBox: CustomInfoBox
      }} mdxType="RedLiner">
    <button style={buttonStyle}>Measure Me</button>
  </RedLiner>
    </Playground>
    <h5 {...{
      "id": "example-custom-infobox-component-1"
    }}>{`Example: Custom InfoBox Component 1`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { defaultComponents } from 'redliner';

export const CustomInfoBox = (props) => (
  <div style={{ border: '1px dotted blue' }}>
    <defaultComponents.InfoBox {...props} />
  </div>
)
`}</code></pre>
    <h5 {...{
      "id": "example-custom-infobox-component-2"
    }}>{`Example: Custom InfoBox Component 2`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Popper } from 'react-popper';
import styles from 'styles/index.scss';

export const CustomInfoBox = (props) => (
  <Popper placement="right">
    {({ ref, style, placement, arrowProps }) => (
      <div className={styles.info} ref={ref} data-placement={placement} style={style}>
        <div className={styles.arrow} ref={arrowProps.ref} style={arrowProps.style} />
          <h6 style={{ fontSize: '16px' }}>Custom Info</h6>
        <span>p: {props.computedStyle.padding}</span>
      </div>
    )}
  </Popper>
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      